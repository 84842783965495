.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  position: relative; /* Ensure proper positioning of elements */
  background-color: #f09559;
  overflow-x: hidden;
}


.header {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  width: 40rem;
  z-index: 1; 
  background-color: #ffffff9c;
}

.header h1 {
  margin: 10px 0;
 font-size: 3rem;
 color: #8a4a38;

}

.social-media {
  margin-top: 20px;
}

.social-media img{
  max-width: 5rem;
  
}

.social-media ul {
  list-style: none;
  padding: 0;
}

.social-media li {
  display: inline;
  margin: 0 10px;
}

.landing {
  position: relative;
}

.background-video {
  object-fit: cover;
  width: 100%;
  height: 40rem; 
}

/* About Me Section Styles */

.about-me {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 20px;
}

.about-me .text-container {
  padding: 20px;
  flex: 1;
}

.about-me h2 {
  color: #8a4a38;
  margin-top: 0;
}

.about-me p {
  color: #666;
  line-height: 1.6;
}

.about-me a {
  color: #8a4a38;
}

.about-me a:hover {
  text-decoration: underline;
}

/* Commissions Section Styles */

.commissions {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.commissions h2 {
  color: #8a4a38;
  margin-top: 0;
  margin-bottom: 20px;
}

.tierContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}







.contact-button {
  background-color: #8a4a38;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #5b2d20;
}

.tosContainer {
  text-align: left;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about-me,
  .commissions,
  .tier {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
}


/* Table Styles */
.tier {
  cursor: pointer; /* Change cursor to pointer */
}

.tier:hover {
  background-color: #eed591; /* Change background color on hover */
}

.tierContainer {
  grid-gap: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
}

.tier {
  background-color: #ffd86e;
  border-radius: 10px;
  box-shadow: 0 2px 4px #0000001a;
  padding: 20px;
  transition: background-color .3s ease;
  text-decoration: none;
}


.tier.active .tierButton {
  display: block;
}

.tier .services {
  display: none;
}

.tier h3 {
  color: #8a4a38;
  margin-top: 0;
}

.tier ul {
  padding-left: 20px;
  list-style: none;
}

.price {
  color: #8a4a38;
  font-weight: bold;
}

.tier a{
  text-decoration: none;
  color: inherit;
}

/* Responsive Table */
@media (max-width: 768px) {
  .tierTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tierTable th,
  .tierTable td {
    white-space: nowrap;
    list-style: none;
  }
}

@media (max-width:493px){
  .header{
    max-width: 25rem;
  }
}
  

@media (max-width:378px){
  .header{
    max-width: 15rem;
  }
  .header h1{
    font-size: 2rem;
  }

}

.gallery-container {
  text-align: center;
  margin-top: 20px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.image-container img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}


  